.topnav-dropdown-header{
    border-bottom: 1px solid $gray-300;
    text-align: center;
    font-size: $font-size-12;
    height: 32px;
    line-height: 32px;
    @include margin-padding(null, 0 12px);
    .notification-title {
        color: $gray-800;
        display: block;
        float: left;
        font-size: $font-size-base;
    }
    .clear-noti {
        color: $danger-700;
        float: right;
        font-size: $font-size-11;
        text-transform: uppercase;
    }
}
.notifications {
    .noti-content {
        height: 290px;
        width: 350px;
        overflow-y: auto;
        position: relative;
    }
    .notification-list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-top: 0;
            border-bottom: 1px solid $gray-100;
            font-size: $font-size-base;
            a {
                display: block;
                @include margin-padding(null, 12px);
                @include rounded(2px);
            }
            .avatar {
                width: 34px;
                height: 34px;
                margin: 0 10px 0 0;
                border-radius: 50%;
                img {
                    border-radius: 50%;
                }
            }
            .noti-title {
                color: $gray-800;
                font-weight: $font-weight-medium;
            }
            .noti-details {
                color: $gray-500;
                @include margin-padding(0, null);
            }
            p.noti-time{
                @include margin-padding(0, null);
            } 
            .notification-time {
                font-size: $font-size-12;
                line-height: 1.35;
                color: $gray-600;
            }
            .list-item {
                border: 0;
                padding: 0;
                position: relative;
                .list-left {
                    height: 48px;
                    position: absolute;
                    width: 48px;
                }
                .list-body {
                    @include margin-padding(null, 0 0 0 50px);
                    .message-author {
                        color: $gray-800;
                        float: left;
                        font-weight: $font-weight-medium;
                        width: 175px;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .message-time {
                        color: $gray-600;
                        float: right;
                        font-size: $font-size-11;
                    }
                    .message-content {
                        color: $gray-800;
                        font-size: $font-size-13;
                        display: block;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
.topnav-dropdown-footer {
    border-top: 1px solid $gray-300;
    font-size: $font-size-12;
    height: 32px;
    line-height: 32px;
    @include margin-padding(null, 0 12px);
    a {
        display: block;
        text-align: center;
        color: $gray-800;
    }
}
.list-left {
    height: 48px;
    position: absolute;
    width: 48px;
}
.list-body {
    @include margin-padding(null, 0 0 0 50px);
    .message-author {
        color: $gray-800;
        float: left;
        font-weight: $font-weight-medium;
        width: 175px;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .message-time {
        color: $gray-600;
        float: right;
        font-size: $font-size-11;
    }
    .message-content {
        color: $gray-800;
        font-size: $font-size-13;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.activity {
    width: 100%;
    .activity-box {
        position: relative;
    }
    .activity-list {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
        li {
            background-color: $white;
            position: relative;
            border: 1px solid $gray-400;
            @include margin-padding(0  0 10px,  20px 10px 10px);
            font-size: $font-size-15;
            .activity-user {
                height: 50px;
                margin: 0;
                padding: 0;
                width: 50px;
                @include position(absolute,12px,null,null,8px);
            }
            .activity-content {
                background-color: $white;
                position: relative;
                @include margin-padding(0 0 0 60px, 0);
            }
            .time {
                color: $gray-500;
                display: block;
                font-size: $font-size-12;
                line-height: 1.35;
            }
            a.name {
                color: $black;;
            }
            a {
                color: $secondary;
                font-weight: $font-weight-semibold;
                font-size: $font-size-15;
                img{
                    width:50px;
                    height:50px;
                    border-radius: 5px;
                }
            }
        }
    }
    
}
